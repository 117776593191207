import React from 'react';
import './index.css';

const ProfilePage = () => {
  const user = {
    name: 'John Doe',
    occupation: 'Frontend Developer',
    about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Fusce sit amet faucibus elit. Nullam auctor est non mi ultricies, at varius ligula cursus. Aliquam vel magna nec arcu tincidunt scelerisque.',
    skills: ['HTML', 'CSS', 'JavaScript', 'React']
  };

  return (
    <div className="profile">
      <div className="profile-header">
        <h1>{user.name}</h1>
        <p>{user.occupation}</p>
      </div>
      <div className="profile-content">
        <h2>About Me</h2>
        <p>{user.about}</p>
        <h2>Skills</h2>
        <ul>
          {user.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProfilePage;
