// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyC-ll_VabfDVTjubyyNbK5NTuuocVJcT74",
    authDomain: "d-e-n-g-dev.firebaseapp.com",
    databaseURL: "https://d-e-n-g-dev.firebaseio.com",
    projectId: "d-e-n-g-dev",
    storageBucket: "d-e-n-g-dev.appspot.com",
    messagingSenderId: "1048524950861",
    measurementId: "G-8ER456FT6Y",
    appId: "1:1048524950861:web:857b9c200ca3cee154fb04"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;