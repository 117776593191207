import React from 'react';
import './index.css';
import { auth } from './firebase';

const HomePage = () => {
  console.log(auth)
  return (
    <div className="home">
      <h1>This is Homepage</h1>
      {/* Add your gaming-related content here */}
    </div>
  );
};

export default HomePage;